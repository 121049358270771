<template>
  <div style="height: 100%">
    <nesi-user-common :title="`房源管理`">
      <template #button>
        <el-button type="primary" plain @click="myRealese"
          >二维码管理</el-button
        >
        <el-button type="primary" plain @click="toStatusSplitPage"
          >房源拆分状态查询</el-button
        >
      </template>
      <template #query>
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="核验码">
            <el-input
              v-model="form.houseVerifyCode"
              placeholder="请输入核验码"
              :clearable="true"
            />
          </el-form-item>
          <el-form-item label="房源坐落">
            <el-input
              v-model="form.location"
              placeholder="请输入房源坐落"
              :clearable="true"
            />
          </el-form-item>
          <!-- <el-form-item label="核验状态">
            <el-select v-model="form.houseStauts" placeholder="核验状态">
              <el-option label="不通过" value="0" />
              <el-option label="核验中" value="1" />
              <el-option label="核验通过" value="2" />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #content>
        <div></div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <el-table-column prop="verificationCode" label="核验码" width="130" />
          <el-table-column label="房源二维码" width="100">
            <template #default="scope">
              <el-popover
                placement="top-start"
                :width="200"
                trigger="hover"
                v-if="scope.row.codePath"
              >
                <template #reference>
                  <div>
                    <qrcode-vue
                      :value="scope.row.codePath"
                      :size="45"
                      level="H"
                    />
                  </div>
                </template>
                <div>
                  <qrcode-vue
                    :value="scope.row.codePath"
                    :size="200"
                    level="H"
                  />
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="location" label="坐落" />
          <el-table-column label="出租方式" width="80">
            <template #default="scope">
              {{ scope.row.rentType === 2 ? '分租' : '整租' }}
            </template>
          </el-table-column>
          <el-table-column label="出租部位" width="80">
            <template #default="scope">
              {{
                scope.row.rentType === 2 && scope.row.lesseeRoomName
                  ? scope.row.lesseeRoomName
                  : '--'
              }}
            </template>
          </el-table-column>
          <el-table-column label="房源面积" width="80">
            <template #default="scope">
              {{ `${scope.row.buildingArea}㎡` }}
            </template>
          </el-table-column>
          <el-table-column prop="upAt" label="挂牌时间" width="120" />
          <el-table-column prop="status" label="挂牌状态" width="120" />
          <el-table-column label="操作" width="150">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                class="list-btn"
                @click="
                  transMethod(
                    scope.row,
                    btnMap.find((item) => item.id == btn).method
                  )
                "
                :key="btn.id"
                v-for="btn in scope.row.buttons"
                >{{ btnMap.find((item) => item.id == btn).text }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="total"
          :page-size="form.pageSize"
          style="text-align: right; margin: 10px; 0"
        />
      </template>
    </nesi-user-common>
    <el-dialog
      v-model="upFormDialogVisible"
      :title="upFormTitle"
      width="80%"
      top="7vh"
    >
      <el-scrollbar height="500px">
        <el-form
          :model="upForm"
          label-width="120px"
          ref="upFormRef"
          :rules="upFormRules"
          inline
        >
          <el-divider content-position="left">填写房屋信息</el-divider>
          <el-form-item label="小区名称" prop="communityName" class="w-p-47">
            <el-autocomplete
              v-model="upForm.communityName"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              clearable
              value-key="name"
              class="inline-input w-50"
              placeholder="请输入小区关键字"
              @select="handleCommutySelect"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item
            label="房源门牌"
            :prop="!upForm.buildingNo ? 'buildingNo' : 'roomNo'"
            class="w-p-47"
          >
            <el-input v-model="upForm.buildingNo" style="width: 50%">
              <template #append>栋</template>
            </el-input>
            <el-input v-model="upForm.roomNo" style="width: 50%">
              <template #append>室</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="房源楼层"
            :prop="
              !upForm.startFloor
                ? 'startFloor'
                : !upForm.terminationFloor
                ? 'terminationFloor'
                : 'buildingTotalFloor'
            "
            class="w-p-47"
          >
            <el-input
              v-model="upForm.startFloor"
              type="number"
              style="width: 31%"
            >
              <template #append>至</template>
            </el-input>
            <el-input
              v-model="upForm.terminationFloor"
              type="number"
              style="width: 38%"
            >
              <template #append>层，总</template>
            </el-input>
            <el-input
              v-model="upForm.buildingTotalFloor"
              type="number"
              style="width: 31%"
            >
              <template #append>层</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="房源户型"
            :prop="
              !upForm.roomNum
                ? 'roomNum'
                : !upForm.hallNum
                ? 'hallNum'
                : 'toiletNum'
            "
            class="w-p-47"
          >
            <el-input v-model="upForm.roomNum" type="number" style="width: 34%">
              <template #append>室</template>
            </el-input>
            <el-input v-model="upForm.hallNum" type="number" style="width: 33%">
              <template #append>厅</template>
            </el-input>
            <el-input
              v-model="upForm.toiletNum"
              type="number"
              style="width: 33%"
            >
              <template #append>卫</template>
            </el-input>
          </el-form-item>
          <el-form-item label="装修类型" prop="situation" class="w-p-47">
            <el-select
              v-model="upForm.situation"
              placeholder="请选择装修类型"
              @change="situationChange"
              style="width: 100%"
            >
              <el-option
                v-for="item in situationMap"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="朝向类型" prop="toward" class="w-p-47">
            <el-select
              v-model="upForm.toward"
              placeholder="请选择朝向类型"
              @change="towardChange"
              style="width: 100%"
            >
              <el-option
                v-for="item in towardMap"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="房源标签" class="w-p-47">
            <el-checkbox-group v-model="upForm.houseTagsArr">
              <el-checkbox label="精装修" name="houseTags" border />
              <el-checkbox label="独立阳台" name="houseTags" border />
              <el-checkbox label="近地铁" name="houseTags" border />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="房东要求" class="w-p-47">
            <el-checkbox-group v-model="upForm.landlordRequestArr">
              <el-checkbox label="禁宠物" name="landlordRequest" border />
              <el-checkbox label="禁烟" name="landlordRequest" border />
              <el-checkbox label="限制女生" name="landlordRequest" border />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="配套设施" class="w-p-97">
            <el-checkbox-group v-model="upForm.collocationNameArr">
              <el-checkbox name="collocationName" label="床" border />
              <el-checkbox name="collocationName" label="电视" border />
              <el-checkbox name="collocationName" label="热水器" border />
              <el-checkbox name="collocationName" label="空调" border />
              <el-checkbox name="collocationName" label="洗衣机" border />
              <el-checkbox name="collocationName" label="冰箱" border />
              <el-checkbox name="collocationName" label="厨房" border />
              <el-checkbox name="collocationName" label="卫生间" border />
              <el-checkbox name="collocationName" label="衣柜" border />
              <el-checkbox
                name="collocationName"
                label="宽带"
                border
                style="margin-left: 0"
              />
              <el-checkbox name="collocationName" label="书桌" border />
              <el-checkbox name="collocationName" label="床头柜" border />
              <el-checkbox name="collocationName" label="书架" border />
              <el-checkbox name="collocationName" label="阳台" border />
              <el-checkbox name="collocationName" label="燃气灶" border />
              <el-checkbox name="collocationName" label="微波炉" border />
              <el-checkbox name="collocationName" label="烤箱" border />
              <el-checkbox name="collocationName" label="椅子" border />
              <el-checkbox
                name="collocationName"
                label="沙发"
                border
                style="margin-left: 0"
              />
              <el-checkbox name="collocationName" label="电话" border />
              <el-checkbox name="collocationName" label="电梯" border />
              <el-checkbox name="collocationName" label="浴缸" border />
              <el-checkbox name="collocationName" label="晾衣架" border />
              <el-checkbox name="collocationName" label="电脑" border />
              <el-checkbox name="collocationName" label="鞋柜" border />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="房源描述" class="w-p-97">
            <el-input
              v-model="upForm.title"
              rows="3"
              type="textarea"
              placeholder="请输入房源描述"
              maxlength="200"
              :show-word-limit="true"
            />
          </el-form-item>
          <el-divider content-position="left">填写出租信息</el-divider>
          <el-form-item label="出租类型" prop="rentType" class="w-p-47">
            <el-select
              v-model="upForm.rentType"
              placeholder="请选择出租类型"
              style="width: 100%"
              disabled
            >
              <!-- { label: '整租', value: 1, disabled: option.allRentDisable },
//     { label: '分租', value: 2, disabled: option.splitRentDisable } -->
              <el-option label="整租" value="1" />
              <el-option label="分租" value="2" />
              <!-- <el-option
                v-for="item in rentTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              /> -->
            </el-select>
          </el-form-item>
          <el-form-item
            label="出租部位"
            prop="roomName"
            class="w-p-47"
            v-if="upForm.rentType === '2'"
          >
            <el-input
              v-model="upForm.roomName"
              placeholder="请输入出租部位"
              disabled
            />
            <!-- <el-cascader
              :options="rentSplitList"
              v-model="upForm.roomName"
              :props="cascaderProps"
              clearable
              style="width: 100%;"
            /> -->
          </el-form-item>
          <el-form-item label="月租金" class="w-p-47" prop="rentPrice">
            <el-input
              v-model="upForm.rentPrice"
              type="number"
              placeholder="请输入月租金"
            >
              <template #append>元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="付款方式" prop="payWay" class="w-p-47">
            <el-select
              v-model="upForm.payWay"
              placeholder="请选择付款方式"
              @change="payWayChange"
              style="width: 100%"
            >
              <el-option
                v-for="item in payWayMap"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-divider content-position="left"
            >房源图片上传(至少三张、且单个大小不得超过6M)</el-divider
          >
          <el-form-item label="房源图片" prop="houseImage">
            <nesi-upload
              @handleFile="handleFile"
              @deleteFile="deleteFile"
              :list="upForm.scans"
            ></nesi-upload>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="upFormDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="upSubmitForm">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="proxyFormDialogVisible"
      title="委托挂牌"
      width="80%"
      top="7vh"
    >
      <el-scrollbar height="500px">
        <el-form
          :model="proxyForm"
          label-width="120px"
          ref="proxyFormRef"
          :rules="proxyFormRules"
          inline
        >
          <el-divider content-position="left">出租信息</el-divider>
          <el-form-item label="企业类型" prop="agentType" class="w-p-47">
            <el-select
              v-model="proxyForm.agentType"
              placeholder="请选择企业类型"
              style="width: 100%"
            >
              <el-option label="代理企业" value="1" />
              <el-option label="托管企业" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="出租类型" prop="rentTypeTemp" class="w-p-47">
            <el-select
              v-model="proxyForm.rentTypeTemp"
              placeholder="请选择出租类型"
              style="width: 100%"
              @change="rentChange"
            >
              <el-option label="整租" value="1" />
              <el-option label="分租" value="2" />
              <el-option label="部分出租" value="3" />
            </el-select>
          </el-form-item>
          <el-form-item
            label="出租部位"
            prop="splitRoom"
            class="w-p-47"
            v-if="proxyForm.rentTypeTemp === '3'"
          >
            <el-cascader
              :options="rentSplitList"
              v-model="proxyForm.splitRoom"
              :props="cascaderProps"
              clearable
              style="width: 100%"
              @change="splitChange"
            />
          </el-form-item>
          <el-divider content-position="left"
            >企业名称*(请勾选企业再提交)
          </el-divider>
          <el-form-item label="企业名称" class="w-p-47">
            <el-input
              v-model="proxyForm.enterpriseName"
              placeholder="请输入企业名称关键字"
              @keyup.enter="getEnterpriseList"
            />
          </el-form-item>
          <el-form-item class="w-p-47">
            <el-button type="primary" @click="getEnterpriseList"
              >查询
            </el-button>
          </el-form-item>
          <el-table
            ref="multipleTableRef"
            :data="enterpriseData"
            style="width: 100%"
            border
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{
              textAlign: 'center',
              backgroundColor: '#f5f7fa'
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="80" />
            <el-table-column
              property="enterpriseName"
              label="企业名称"
              width="400"
            />
            <el-table-column property="officeAddress" label="办公室地址" />
            <el-table-column property="type" label="企业类型" width="150" />
          </el-table>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="proxyFormDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="proxySubmitForm">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import NesiUpload from '@/components/NesiUpload/index.vue'
import { reactive, computed, onActivated, ref, defineComponent } from 'vue'
import {
  getHouseList,
  houseOffShelves,
  getHouseById,
  // getSubleaseHouse,
  savePersonAgentHouse,
  houseUpShelves,
  delOwnerHouse,
  getEnterpriseInfo
} from '@/api/user-house'
import { getCommunityList } from '@/api/sys'
// import { getRentSelectOptions, getRentBwSelectOptions } from '@/utils/split'
import QrcodeVue from 'qrcode.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
defineComponent({
  NesiUserCommon,
  NesiUpload,
  QrcodeVue
})

const router = useRouter()

const form = reactive({
  houseVerifyCode: '',
  location: '',
  houseStauts: '',
  type: '1',
  pageNo: 1,
  pageSize: 8
})

const total = ref(0)
const tableData = ref([])
const getOwnHouseList = async () => {
  const resp = await getHouseList(form)
  total.value = resp.total
  minioUrl.value = resp.records.countId
  tableData.value = handleList(resp.records)
}

const btnMap = ref([
  { id: 1, text: '上架房源', method: 'upHouse' },
  { id: 2, text: '下架房源', method: 'downHouse' },
  { id: 3, text: '删除房源', method: 'delHouse' }, // 在审核过了才会展示撤销房源
  { id: 4, text: '修改信息', method: 'modUpHouse' },
  { id: 5, text: '删除房源', method: 'delHouse' }, // 在审核过了才会展示删除房源
  { id: 6, text: '托管备案', method: 'trustee' },
  { id: 7, text: '生成房源', method: 'createHouse' },
  { id: 8, text: '证明下载', method: 'download' },
  { id: 9, text: '申请注销', method: 'bindClick' },
  { id: 10, text: '备案信息', method: '' },
  { id: 11, text: '状态回退', method: '' },
  { id: 12, text: '查看原因', method: 'seeReason' },
  { id: 13, text: '查看房源', method: 'seeHouse' },
  { id: 14, text: '补充材料', method: 'suppleMaterial' }, // 个人，企业自持发房审核回退补充材料
  { id: 15, text: '撤销核验', method: 'cancelValidate' },
  { id: 16, text: '补充材料', method: 'trustInfo' }, // 托管发房补充材料
  { id: 17, text: '同意接收', method: 'agreeAgent' }, // 居间代理业主或居间经理分配给经纪人，同意接收该房源
  { id: 18, text: '拒绝接收', method: 'rejectAgent' }, // 居间代理业主或居间经理分配给经纪人，拒绝接收该房源
  { id: 19, text: '分配房源', method: 'assign' }, // 居间经理分配房源给经纪人
  { id: 20, text: '委托情况', method: 'agent' }, // 居间代理情况下查看委托人员是否接收情况
  { id: 21, text: '新增委托', method: 'addAgent' } // 个人用户申请核验后，在其记录上新增新增委托功能
])

const statusMap = [
  { id: 0, text: '管理员强制下架', buttons: [12] }, // 无操作按钮
  { id: 1, text: '待上架', buttons: [1, 3] }, // 按钮：上架、发房撤销
  { id: 2, text: '上架成功', buttons: [2, 4] }, // 按钮：下架，变更上架信息
  { id: 3, text: '已网签', buttons: [3] }, // 按钮：删除
  { id: 4, text: '待备案', buttons: [3, 6] }, // 按钮：撤销、托管备案
  { id: 5, text: '备案成功', buttons: [7, 8, 9], type: 15 }, // 按钮：生成房源、证明下载、注销申请、备案信息
  { id: 6, text: '托管待注销', buttons: [] }, // 按钮：备案信息
  { id: 7, text: '托管已注销', buttons: [], type: 15 }, // 按钮：备案信息
  { id: 8, text: '托管注销拒绝', buttons: [11], type: 15 }, // 按钮：备案信息, 状态回退
  { id: 9, text: '备案成功自动下架', buttons: [3] }, // 按钮：删除
  { id: 10, text: '未曾上架', buttons: [] }, // 无操作按钮
  { id: 12, text: '备案下架', buttons: [] } // 备案下架
]

// 房源审核状态以及需展示的按钮
const verificationMap = [
  { id: 0, text: '审核不通过', buttons: [3, 12, 13] }, // 按钮：撤销、查看原因、查看房源
  { id: 1, text: '申请核验中', buttons: [3, 13] /*, 15 */ }, // 按钮展示：查看房源、审核撤销
  { id: 2, text: '审核通过', buttons: [] }, // 审核通过看 statusMap 的 text 值
  { id: 3, text: '审核回退', buttons: [3, 12, 13, 14] /* 21 , 15 */ }, // 按钮展示：查看原因、查看房源、变更信息、审核撤销
  { id: 4, text: '撤销申请成功', buttons: [] }, // 无操作按钮
  { id: 5, text: '撤销申请失败', buttons: [12] /*, 15 */ } // 按钮：审核撤销、查看原因
]

// 托管在 statusMap 为 4 的情况下
// const trustStatus = [
//   { id: 1, text: '托管备案审核回退', buttons: [3, 12, 16] }, // 按钮：撤销房源，查看原因，托管变更信息
//   { id: 2, text: '托管备案审核中', buttons: [3] }, // 按钮：撤销房源
//   // { id: 3, text: '托管备案通过', buttons: [] }, // 按钮展示：撤销房源
//   { id: 4, text: '托管备案不通过', buttons: [3] }, // 按钮展示：撤销房源
//   { id: 5, text: '托管备案撤销异常', buttons: [3, 12, 16] } // 按钮：撤销房源，查看原因，托管变更信息
// ]

// 房源类型为17或者非17情况
const typeStatus = [
  { id: 1, text: '待上架', buttons: [1] }, // 按钮：上架、发房撤销
  { id: 2, text: '上架成功', buttons: [2, 4] }, // 按钮：下架，变更上架信息
  { id: 3, text: '待接收房源', buttons: [17, 18] }, // 按钮：同意接收，拒绝接收
  { id: 4, text: '已拒绝接收房源', buttons: [] }, // 按钮展示：无
  { id: 5, text: '委托代理', buttons: [13, 20] } // 按钮：下架，变更上架信息
]

const myRealese = () => {
  router.push('/user/house/realese')
}
const toStatusSplitPage = () => {
  router.push('/user/house/splitStatus')
}

const handleList = (list) => {
  const newList = list.map((item) => {
    const verification = verificationMap.find(
      (verification) => verification.id === item.govVerification
    )
    const statusOp = statusMap.find(
      (statusItem) => statusItem.id === item.upStatus
    )
    let status = ''
    let buttons = []
    if (verification.id !== 2) {
      status = verification.text
      buttons = verification.buttons
      if (item.type === 17 && buttons.find((item) => item === 21) > -1) {
        buttons = buttons.filter((item) => item !== 21)
      }
    } else if (item.type === 17) {
      status = typeStatus[4].text
      buttons = typeStatus[4].buttons
    } else {
      if (statusOp) {
        status = statusOp.text
        buttons = statusOp.buttons
      } else {
        status = ''
        buttons = []
      }
    }
    return {
      ...item,
      type: '1', // 个人房源
      status,
      buttons
    }
  })
  return newList
}

const transMethod = (item, method) => {
  switch (method) {
    case 'upHouse':
      upFormStatus.value = '1'
      upHouse(item)
      break
    case 'downHouse':
      downHouse(item)
      break
    case 'delHouse':
      delHouse(item)
      break
    case 'modUpHouse':
      upFormStatus.value = '2'
      upHouse(item)
      break
    case 'seeReason':
      ElMessageBox.alert(item.statusMsg, '温馨提示', {
        confirmButtonText: '确定'
      })
      break
    case 'seeHouse':
      houseShowDetail(item)
      break
    case 'trustee':
      // this.download(item.id)
      break
    case 'createHouse':
      // this.trustInfo(item.id)
      break
    case 'cancelValidate':
      // ElMessage({ type: 'error', message: '功能开发中，敬请期待' })
      break
    case 'suppleMaterial':
      // suppleMaterial(item)
      break
    case 'agreeAgent':
      // agentAccept(item, 1)
      break
    case 'rejectAgent':
      // agentAccept(item, 2)
      break
    case 'agent':
      // agentList(item, false)
      break
    case 'assign':
      // assign(item)
      break
    case 'addAgent':
      addAgent(item)
      break
    default:
      return ''
  }
}

/* *
 * 房源详情
 */
const houseShowDetail = (item) => {
  if (item.source) {
    window.open(item.link_address, '_blank')
  } else {
    const routeUrl = router.resolve({
      name: 'houseDetail',
      query: { id: item.houseId }
    })
    window.open(routeUrl.href, '_blank')
  }
}

const addAgent = (item) => {
  proxyHouse(item)
}

const delHouse = (item) => {
  ElMessageBox.prompt('删除理由：', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
    inputErrorMessage: '删除房源理由不能为空'
  })
    .then(async ({ value }) => {
      const resp = await delOwnerHouse({
        houseId: item.houseId,
        reason: value,
        type: '1'
      })
      ElMessage.success(resp)
      getOwnHouseList()
    })
    .catch(() => {})
}

const downHouse = (item) => {
  ElMessageBox.prompt('请输入下架理由', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
    inputErrorMessage: '下架理由不能为空'
  })
    .then(async ({ value }) => {
      const resp = await houseOffShelves({
        id: item.houseId,
        reason: value,
        type: '1'
      })
      ElMessage.success(resp)
      getOwnHouseList()
    })
    .catch(() => {})
}

const upFormDialogVisible = ref(false)
// 企业委托
const proxyFormDialogVisible = ref(false)
const proxyForm = reactive({
  agentType: '',
  houseId: '',
  orgs: [],
  rentTypeTemp: '',
  rentType: '',
  roomName: '',
  splitRoom: '',
  roomNum: '',
  roomSpace: 0,
  roomType: '',
  zlbw: '',
  enterpriseName: ''
})

const proxyFormRef = ref(null)
const proxyFormRules = reactive({
  agentType: [{ required: true, message: '企业类型不能为空', trigger: 'blur' }],
  rentTypeTemp: [
    { required: true, message: '出租类型不能为空', trigger: 'blur' }
  ],
  splitRoom: [{ required: true, message: '出租部位不能为空', trigger: 'blur' }]
})

const proxySubmitForm = () => {
  proxyFormRef.value.validate(async (valid) => {
    if (!valid) return
    if (!proxyForm.orgs.length) {
      ElMessage.error('请输入企业名称关键字查询后，选择委托企业')
      return
    }
    const resp = await savePersonAgentHouse(proxyForm)
    ElMessage.success(resp)
    proxyFormDialogVisible.value = false
    getOwnHouseList()
  })
}

const proxyHouse = (item) => {
  proxyFormDialogVisible.value = true
  proxyForm.houseId = item.houseId
  // 出租类型
  // getRentTypes(houseInfo)
}

const originRoomList = ref([])
const originUpRoomList = ref([])
const getRentTypes = async (houseInfo) => {
  if (!originRoomList.value.length) {
    const resp = await getSubleaseHouse({
      houseId: houseInfo.houseId,
      houseNum: houseInfo.houseNumber,
      parentRoomNum: ''
    })
    originRoomList.value = resp.subleaseEntitys
    originUpRoomList.value = resp.upHouses
  }
  const option = getRentSelectOptions(
    originRoomList.value,
    originUpRoomList.value,
    houseInfo.lesseeRoomNumber,
    houseInfo.sublease
  )
  const allRentText = option.upHouseFlag ? '整租(已上架)' : '整租'
  rentTypeList.value = [
    { label: allRentText, value: 1, disabled: option.allRentDisable },
    { label: '分租', value: 2, disabled: option.splitRentDisable }
  ]
  rentSplitList.value = getRentBwSelectOptions(
    originRoomList.value,
    houseInfo.lesseeRoomNumber,
    houseInfo.sublease,
    originUpRoomList.value
  )
}

const getEnterpriseList = async () => {
  if (proxyForm.enterpriseName) {
    const resp = await getEnterpriseInfo({
      enterpriseName: proxyForm.enterpriseName
    })
    if (resp.length) {
      enterpriseData.value = resp.map((item) => {
        return {
          ...item,
          type: '企业'
        }
      })
    }
  } else {
    enterpriseData.value = []
  }
}

const splitChange = (value) => {
  proxyForm.roomNum = value[0].split('|')[0]
  proxyForm.roomSpace = value[0].split('|')[1]
  proxyForm.roomType = value[0].split('|')[2]
  proxyForm.roomName = value[0].split('|')[3]
  proxyForm.zlbw = value[0].split('|')[3]
}

const handleSelectionChange = (value) => {
  if (value.length) {
    proxyForm.orgs = value.map((item) => {
      return {
        orgName: item.enterpriseName,
        orgId: item.enterpriseNo
      }
    })
  }
}

const rentChange = (value) => {
  if (value === '1' || value === '2') {
    ;[
      proxyForm.roomNum,
      proxyForm.roomSpace,
      proxyForm.roomType,
      proxyForm.roomName,
      proxyForm.zlbw
    ] = ['', '', '', '', '']
  }
  proxyForm.rentType = value === '1' ? '1' : '2'
}
// 用来存储查询到的委托企业
const enterpriseData = ref([])

// 用来存储某个房源已委托的企业列表
const proxyEnterpriseData = ref([])
const entDialogVisible = ref(false)
const getEnterprise = async (item) => {
  entDialogVisible.value = true
  const resp = await queryPersonAgentHouse({
    checkCode: item.gov_house_number
  })
  proxyEnterpriseData.value = resp
}

const upFormStatus = ref('1')
const upFormTitle = computed(() =>
  upFormStatus.value === '1' ? '房源上架' : '房源修改'
)
const upForm = reactive({
  communityId: '',
  communityName: '',
  buildingNo: '',
  roomNo: '',

  startFloor: '',
  terminationFloor: '',
  buildingTotalFloor: '',

  // 厅室卫
  hallNum: '',
  roomNum: '',
  toiletNum: '',
  houseId: '',

  houseTags: '',
  houseTagsArr: [], // 数据展示用
  landlordRequest: '',
  landlordRequestArr: [], // 数据展示用
  collocationName: '',
  collocationNameArr: [], // 数据展示用

  parentNum: '',
  payWay: '',
  payWayName: '',
  rentPrice: '',
  rentType: '',

  // 拆分需上传出租部位
  roomId: '',
  roomName: '',
  roomSpace: '',
  roomType: '',

  // 图片附件
  scans: [],
  situation: '',
  situationName: '',
  title: '',
  toward: '',
  towardName: '',
  type: '1'
})

const validatorImage = (rule, value, callback) => {
  if (!upForm.scans.length) {
    callback(new Error('请上传房源图片'))
  } else if (rule.minLength && upForm.scans.length < rule.minLength) {
    callback(new Error('请上传至少3张房源图片'))
  } else {
    callback()
  }
}

const upFormRules = reactive({
  communityName: [
    { required: true, message: '小区名称不能为空', trigger: 'blur' }
  ],
  buildingNo: [
    { required: true, message: '房屋楼栋不能为空', trigger: 'blur' }
  ],
  roomNo: [{ required: true, message: '房屋室号不能为空', trigger: 'blur' }],
  startFloor: [
    { required: true, message: '起始楼层不能为空', trigger: 'blur' }
  ],
  terminationFloor: [
    { required: true, message: '终止楼层不能为空', trigger: 'blur' }
  ],
  buildingTotalFloor: [
    { required: true, message: '总楼层不能为空', trigger: 'blur' }
  ],
  hallNum: [{ required: true, message: '房屋厅数量不能为空', trigger: 'blur' }],
  roomNum: [{ required: true, message: '房屋室数量不能为空', trigger: 'blur' }],
  toiletNum: [
    { required: true, message: '房屋卫生间数量不能为空', trigger: 'blur' }
  ],
  situation: [{ required: true, message: '装修类型不能为空', trigger: 'blur' }],
  toward: [{ required: true, message: '朝向类型不能为空', trigger: 'blur' }],
  rentType: [{ required: true, message: '出租类型不能为空', trigger: 'blur' }],
  roomName: [{ required: true, message: '出租部位不能为空', trigger: 'blur' }],
  rentPrice: [{ required: true, message: '月租金不能为空', trigger: 'blur' }],
  payWay: [{ required: true, message: '付款方式不能为空', trigger: 'blur' }],
  houseImage: [
    {
      validator: validatorImage,
      trigger: 'blur',
      minLength: 3
    }
  ]
})

const situationMap = [
  { label: '毛坯', value: '1' },
  { label: '普通装修', value: '2' },
  { label: '精装修', value: '3' },
  { label: '豪华装修', value: '4' }
]

const situationChange = (value) => {
  upForm.situationName = situationMap.find((item) => item.value === value).label
}

const towardMap = [
  { label: '东', value: '1' },
  { label: '南', value: '2' },
  { label: '西', value: '3' },
  { label: '北', value: '4' },
  { label: '东南', value: '5' },
  { label: '西南', value: '6' },
  { label: '东北', value: '7' },
  { label: '西北', value: '8' },
  { label: '南北', value: '9' },
  { label: '东西', value: '10' },
  { label: '暂无朝向', value: '11' }
]

const towardChange = (value) => {
  upForm.towardName = towardMap.find((item) => item.value === value).label
}

const payWayMap = [
  { value: '1', label: '押一付三' },
  { value: '2', label: '付二押一' },
  { value: '3', label: '付一押一' },
  { value: '4', label: '付三押二' },
  { value: '5', label: '半年付' },
  { value: '6', label: '整年付' },
  { value: '7', label: '面议' },
  { value: '8', label: '付一押二' },
  { value: '9', label: '季付' },
  { value: '10', label: '付一押三' },
  { value: '11', label: '月付' }
]

const payWayChange = (value) => {
  upForm.payWayName = payWayMap.find((item) => item.value === value).label
}

const handleFile = (value) => {
  upForm.scans.push({
    name: value.name,
    smjmc: value.name,
    smjsj: value.path,
    uid: value.uid,
    url: value.url
  })
}
const deleteFile = (value) => {
  upForm.scans = upForm.scans.filter((item) => item.uid !== value.uid)
}

const minioUrl = ref('')

const upHouse = async (item) => {
  upFormDialogVisible.value = true
  // 查询小区信息
  const resp = await getHouseById({ houseId: item.houseId })
  const houseInfo = resp.houseIndfo
  minioUrl.value = resp.minioUrl

  /**
   * 小区名称，如果后台返回的小区ID有值，则不用再去查小区信息列表，如果没有值，且小区名称不为空，则
   * 根据小区名称精准查询小区信息，如果有饭会友结果，则直接赋值小区ID，否则查询小区信息列表，让用户
   * 自己选择
   */
  if (houseInfo.communityId) {
    upForm.communityName = houseInfo.communityName
    upForm.communityId = houseInfo.communityId
  } else {
    if (houseInfo.communityName) {
      getCommutyListByName(houseInfo.communityName)
    }
  }

  // 房源门牌
  upForm.buildingNo = houseInfo.buildingNo
    ? houseInfo.buildingNo.split('栋')[0]
    : ''
  upForm.roomNo = houseInfo.roomNo

  // 楼层
  upForm.startFloor = houseInfo.startFloor
  upForm.terminationFloor = houseInfo.terminationFloor
  upForm.buildingTotalFloor = houseInfo.buildingTotalFloor

  // 室厅卫
  upForm.hallNum = houseInfo.hallNum
  upForm.roomNum = houseInfo.roomNum
  upForm.toiletNum = houseInfo.toiletNum

  if (houseInfo.houseTags) {
    upForm.houseTags = houseInfo.houseTags
    upForm.houseTagsArr = houseInfo.houseTags
      ? houseInfo.houseTags.split(',')
      : []
  }

  if (houseInfo.landlordRequest) {
    upForm.landlordRequest = houseInfo.landlordRequest
    upForm.landlordRequestArr = houseInfo.landlordRequest
      ? houseInfo.landlordRequest.split(',')
      : []
  }

  if (houseInfo.collocationName) {
    upForm.collocationName = houseInfo.collocationName
    upForm.collocationNameArr = houseInfo.collocationName
      ? houseInfo.collocationName.split(',')
      : []
  }

  upForm.title = houseInfo.title

  upForm.rentPrice = houseInfo.rentPrice ? houseInfo.rentPrice : ''
  upForm.payWay = houseInfo.payWay ? houseInfo.payWay.toString() : ''
  upForm.payWayName = upForm.payWay.toString()
    ? payWayMap.find((item) => item.value === houseInfo.payWay.toString()).label
    : ''

  upForm.toward = houseInfo.toward ? houseInfo.toward.toString() : ''
  upForm.towardName = houseInfo.towardName

  upForm.situation = houseInfo.situation ? houseInfo.situation.toString() : ''
  upForm.situationName = houseInfo.situationName

  upForm.houseId = houseInfo.houseId

  if (houseInfo.houseOther) {
    const imageList = JSON.parse(houseInfo.houseOther)
    if (imageList && imageList.length) {
      upForm.scans = imageList.map((imageItem) => {
        const tempUrl = imageItem.src || imageItem.smjsj
        return {
          name: imageItem.smjmc,
          uid: new Date().getTime() + '_' + imageItem.smjmc,
          url:
            tempUrl.indexOf('http') != -1 ? tempUrl : minioUrl.value + tempUrl,
          ...imageItem
        }
      })
    } else {
      upForm.scans = []
    }
  } else {
    upForm.scans = []
  }

  // 出租类型
  upForm.rentType = houseInfo.rentType ? houseInfo.rentType.toString() : ''
  if (upForm.rentType === '2') {
    upForm.roomName = houseInfo.lesseeRoomName
    upForm.roomId = houseInfo.lesseeRoomNumber
    upForm.roomSpace = houseInfo.lesseeSpace
    upForm.roomType = houseInfo.roomType
  }
}

const getCommutyListByName = async (communityName) => {
  const resp = await getCommunityList({ communityName, type: 1 })
  if (resp.length) {
    upForm.communityName = resp[0].communityName
    upForm.communityId = resp[0].communityId
  } else {
    getAllCommutityList()
  }
}

const getAllCommutityList = async () => {
  if (!communityData.value.length) {
    communityData.value = await getCommunityList({})
  }
}

const communityData = ref([])

const querySearch = (queryString, cb) => {
  const results = queryString
    ? communityData.value.filter(createFilter(queryString))
    : communityData.value
  // call callback function to return suggestions
  cb(results)
}

const createFilter = (queryString) => {
  return (community) => {
    return community.name.indexOf(queryString) === 0
  }
}
const handleCommutySelect = (value) => {
  upForm.communityId = value.id
}

const upFormRef = ref(null)
const upSubmitForm = () => {
  upFormRef.value.validate(async (valid) => {
    if (!valid) return false
    upForm.houseTags = upForm.houseTagsArr.join(',')
    upForm.landlordRequest = upForm.landlordRequestArr.join(',')
    upForm.collocationName = upForm.collocationNameArr.join(',')
    const resp = await houseUpShelves(upForm)
    ElMessage.success(resp)
    upFormDialogVisible.value = false
    getOwnHouseList()
  })
}

onActivated(() => {
  getOwnHouseList()
})

const onSubmit = () => {
  getOwnHouseList()
}

// const deleteRow = () => {
// }
</script>

<style lang="scss" scoped>
.w-p-47 {
  width: 47%;
}
.w-p-97 {
  width: 97%;
}
</style>
